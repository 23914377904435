<template>

    <div class="row text-center">
        <div v-if="!recipe" class="text-center" style="height:400px; text-align: center;margin-left: 550px;">
            <v-progress-circular
                class="mx-auto text-center mt-5"
                :size="90"
                color="amber"
                indeterminate
            ></v-progress-circular>
        </div>
        <div v-else class="col-md-8 mx-auto mt-5">
            <h1>{{ recipe.title }}</h1>
            <img class="img-fluid recipe-img" :src="recipe.thumbnail" alt="">

            <div v-if="recipe.details" class="mt-5">
                <h3 style="margin-bottom: 50px;">Recipe Details</h3>
                <p class="text-justify details">
                    <span v-html="recipe.details"></span>
                </p>
            </div>
            
            <div v-if="recipe.nutrition_details" class="mt-5">
                <h3 style="margin: 30px 0;">Recipe Nutrition Details</h3>
                <p class="text-justify details">
                    <span v-html="recipe.nutrition_details"></span>
                </p>
            </div>
            
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
//import Carousel from '@/components/Singlerecipe/Carousel'
export default {
    name: 'single-recipe',
    //components: {Carousel},
    data () {
        return {
            resInfo: {id: '', catId: ''},
            loading:false,
        }
    },
    computed: {
        ...mapGetters({
            recipe: 'recipe/GET_RECIPE'
        })
    }, //end of computed

    methods:{
        fetchRecipe(){
        let id = this.$route.params.id || null
        let catId = this.$route.query.catId || null
        this.resInfo.id = id
        this.resInfo.catId = catId
        //console.log(this.resInfo, '?>>>>')
        this.$store.dispatch('recipe/ACT_RECIPE_LIST', this.resInfo)
        }

    },

    watch: {
  $route(to, from) {
    if (to && from && (to.params.id !== from.params.id || to.query.catId !== from.query.catId)) {
      this.fetchRecipe();
    }
  },
},

    mounted(){
        this.fetchRecipe()
    },
  
    
}
</script>

<style scoped>
    h1,h2,h3,h4,h5,h6,p{
        font-family: 'Ubuntu', sans-serif;
    }
    p.details p {
        font-size: 14px !important;
    }
    .recipe-img {
        min-width: 100%;
        height: 500px;
    }
</style>